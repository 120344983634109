@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700");
html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

/*********************
BREAKPOINTS
*********************/
body {
  margin: 0 25px; }
  @media (min-width: 620px) {
    body {
      margin: 0; } }

a {
  outline: none; }

b, strong {
  font-weight: inherit;
  font-weight: 600; }

.push-left {
  float: left;
  clear: both; }

.push-right {
  float: right;
  clear: both; }

.wrapper {
  position: relative;
  opacity: 1; }
  .loading .wrapper {
    opacity: 0; }

/* Plugin Happy Social */
.hpscl {
  display: flex;
  margin-top: 50px; }

.block-revealer__element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  pointer-events: none;
  opacity: 0;
  z-index: 10; }

.preload {
  position: fixed;
  top: 100%;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #dddddd; }
  .loading .preload {
    top: 0;
    z-index: 1000; }
  .preload .loader {
    position: absolute;
    left: 0;
    bottom: 60%;
    width: 100%;
    opacity: 0;
    cursor: default;
    pointer-events: none; }
    .preload .loader .pulse {
      width: 40px;
      height: 40px;
      margin: 100px auto 10px;
      background-color: #fff;
      background-color: #000;
      border-radius: 100%;
      -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
      animation: sk-scaleout 1.0s infinite ease-in-out; }

@keyframes sk-scaleout {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1);
    opacity: 0; } }
    .preload .loader .percent {
      font-size: 20px;
      color: #000; }
    .loading .preload .loader {
      opacity: 1;
      animation: animInitialHeader 1s cubic-bezier(0.7, 0, 0.3, 1) both;
      animation-delay: 0.2s; }

@keyframes animInitialHeader {
  from {
    opacity: 0;
    transform: translate3d(0, 800px, 0); } }

.container {
  max-width: 970px;
  margin-left: auto;
  margin-right: auto; }
  .container::after {
    clear: both;
    content: "";
    display: block; }
  .container .main,
  .container .sidebar {
    display: block; }
    .container .main::after,
    .container .sidebar::after {
      clear: both;
      content: "";
      display: block; }

@media screen and (min-width: 620px) {
  .main {
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 100%; }
    .main:last-child {
      margin-right: 0; }
    .sidebar-primary .main {
      float: left;
      display: block;
      margin-right: 2.8425860857%;
      width: 58.8629655657%; }
      .sidebar-primary .main:last-child {
        margin-right: 0; }
  .sidebar {
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 38.2944483486%; }
    .sidebar:last-child {
      margin-right: 0; } }

.container {
  max-width: 970px;
  margin-left: auto;
  margin-right: auto; }
  .container::after {
    clear: both;
    content: "";
    display: block; }

.row {
  display: block; }
  .row::after {
    clear: both;
    content: "";
    display: block; }

.col {
  float: left;
  display: inline-block; }

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12 {
  position: relative;
  min-height: 1px; }

.col-xs-1 {
  float: left;
  float: left;
  display: block;
  margin-right: 2.8425860857%;
  width: 7.4416725228%; }
  .col-xs-1:last-child {
    margin-right: 0; }

.col-xs-2 {
  float: left;
  float: left;
  display: block;
  margin-right: 2.8425860857%;
  width: 17.7259311314%; }
  .col-xs-2:last-child {
    margin-right: 0; }

.col-xs-3 {
  float: left;
  float: left;
  display: block;
  margin-right: 2.8425860857%;
  width: 28.01018974%; }
  .col-xs-3:last-child {
    margin-right: 0; }

.col-xs-4 {
  float: left;
  float: left;
  display: block;
  margin-right: 2.8425860857%;
  width: 38.2944483486%; }
  .col-xs-4:last-child {
    margin-right: 0; }

.col-xs-5 {
  float: left;
  float: left;
  display: block;
  margin-right: 2.8425860857%;
  width: 48.5787069571%; }
  .col-xs-5:last-child {
    margin-right: 0; }

.col-xs-6 {
  float: left;
  float: left;
  display: block;
  margin-right: 2.8425860857%;
  width: 58.8629655657%; }
  .col-xs-6:last-child {
    margin-right: 0; }

.col-xs-7 {
  float: left;
  float: left;
  display: block;
  margin-right: 2.8425860857%;
  width: 69.1472241743%; }
  .col-xs-7:last-child {
    margin-right: 0; }

.col-xs-8 {
  float: left;
  float: left;
  display: block;
  margin-right: 2.8425860857%;
  width: 79.4314827829%; }
  .col-xs-8:last-child {
    margin-right: 0; }

.col-xs-9 {
  float: left;
  float: left;
  display: block;
  margin-right: 2.8425860857%;
  width: 89.7157413914%; }
  .col-xs-9:last-child {
    margin-right: 0; }

.col-xs-10 {
  float: left;
  display: block;
  margin-right: 2.8425860857%;
  width: 100%; }
  .col-xs-10:last-child {
    margin-right: 0; }

@media screen and (min-width: 768px) {
  .container {
    max-width: 768px; }
  .col-sm-1 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 7.4416725228%; }
    .col-sm-1:last-child {
      margin-right: 0; }
  .col-sm-push-1 {
    left: 10.2842586086%; }
  .col-sm-pull-1 {
    right: 10.2842586086%; }
  .col-sm-2 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 17.7259311314%; }
    .col-sm-2:last-child {
      margin-right: 0; }
  .col-sm-push-2 {
    left: 20.5685172171%; }
  .col-sm-pull-2 {
    right: 20.5685172171%; }
  .col-sm-3 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 28.01018974%; }
    .col-sm-3:last-child {
      margin-right: 0; }
  .col-sm-push-3 {
    left: 30.8527758257%; }
  .col-sm-pull-3 {
    right: 30.8527758257%; }
  .col-sm-4 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 38.2944483486%; }
    .col-sm-4:last-child {
      margin-right: 0; }
  .col-sm-push-4 {
    left: 41.1370344343%; }
  .col-sm-pull-4 {
    right: 41.1370344343%; }
  .col-sm-5 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 48.5787069571%; }
    .col-sm-5:last-child {
      margin-right: 0; }
  .col-sm-push-5 {
    left: 51.4212930429%; }
  .col-sm-pull-5 {
    right: 51.4212930429%; }
  .col-sm-6 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 58.8629655657%; }
    .col-sm-6:last-child {
      margin-right: 0; }
  .col-sm-push-6 {
    left: 61.7055516514%; }
  .col-sm-pull-6 {
    right: 61.7055516514%; }
  .col-sm-7 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 69.1472241743%; }
    .col-sm-7:last-child {
      margin-right: 0; }
  .col-sm-push-7 {
    left: 71.98981026%; }
  .col-sm-pull-7 {
    right: 71.98981026%; }
  .col-sm-8 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 79.4314827829%; }
    .col-sm-8:last-child {
      margin-right: 0; }
  .col-sm-push-8 {
    left: 82.2740688686%; }
  .col-sm-pull-8 {
    right: 82.2740688686%; }
  .col-sm-9 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 89.7157413914%; }
    .col-sm-9:last-child {
      margin-right: 0; }
  .col-sm-push-9 {
    left: 92.5583274772%; }
  .col-sm-pull-9 {
    right: 92.5583274772%; }
  .col-sm-10 {
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 100%; }
    .col-sm-10:last-child {
      margin-right: 0; }
  .col-sm-push-10 {
    left: 102.842586086%; }
  .col-sm-pull-10 {
    right: 102.842586086%; } }

@media screen and (min-width: 992px) {
  .container {
    max-width: 992px; }
  .col-md-1 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 7.4416725228%; }
    .col-md-1:last-child {
      margin-right: 0; }
  .col-md-push-1 {
    left: 10.2842586086%; }
  .col-md-pull-1 {
    right: 10.2842586086%; }
  .col-md-2 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 17.7259311314%; }
    .col-md-2:last-child {
      margin-right: 0; }
  .col-md-push-2 {
    left: 20.5685172171%; }
  .col-md-pull-2 {
    right: 20.5685172171%; }
  .col-md-3 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 28.01018974%; }
    .col-md-3:last-child {
      margin-right: 0; }
  .col-md-push-3 {
    left: 30.8527758257%; }
  .col-md-pull-3 {
    right: 30.8527758257%; }
  .col-md-4 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 38.2944483486%; }
    .col-md-4:last-child {
      margin-right: 0; }
  .col-md-push-4 {
    left: 41.1370344343%; }
  .col-md-pull-4 {
    right: 41.1370344343%; }
  .col-md-5 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 48.5787069571%; }
    .col-md-5:last-child {
      margin-right: 0; }
  .col-md-push-5 {
    left: 51.4212930429%; }
  .col-md-pull-5 {
    right: 51.4212930429%; }
  .col-md-6 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 58.8629655657%; }
    .col-md-6:last-child {
      margin-right: 0; }
  .col-md-push-6 {
    left: 61.7055516514%; }
  .col-md-pull-6 {
    right: 61.7055516514%; }
  .col-md-7 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 69.1472241743%; }
    .col-md-7:last-child {
      margin-right: 0; }
  .col-md-push-7 {
    left: 71.98981026%; }
  .col-md-pull-7 {
    right: 71.98981026%; }
  .col-md-8 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 79.4314827829%; }
    .col-md-8:last-child {
      margin-right: 0; }
  .col-md-push-8 {
    left: 82.2740688686%; }
  .col-md-pull-8 {
    right: 82.2740688686%; }
  .col-md-9 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 89.7157413914%; }
    .col-md-9:last-child {
      margin-right: 0; }
  .col-md-push-9 {
    left: 92.5583274772%; }
  .col-md-pull-9 {
    right: 92.5583274772%; }
  .col-md-10 {
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 100%; }
    .col-md-10:last-child {
      margin-right: 0; }
  .col-md-push-10 {
    left: 102.842586086%; }
  .col-md-pull-10 {
    right: 102.842586086%; } }

@media screen and (min-width: 970px) {
  .container {
    max-width: 970px;
    margin-left: auto;
    margin-right: auto; }
    .container::after {
      clear: both;
      content: "";
      display: block; }
  .col-lg-1 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 7.4416725228%; }
    .col-lg-1:last-child {
      margin-right: 0; }
  .col-lg-push-1 {
    left: 10.2842586086%; }
  .col-lg-pull-1 {
    right: 10.2842586086%; }
  .col-lg-2 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 17.7259311314%; }
    .col-lg-2:last-child {
      margin-right: 0; }
  .col-lg-push-2 {
    left: 20.5685172171%; }
  .col-lg-pull-2 {
    right: 20.5685172171%; }
  .col-lg-3 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 28.01018974%; }
    .col-lg-3:last-child {
      margin-right: 0; }
  .col-lg-push-3 {
    left: 30.8527758257%; }
  .col-lg-pull-3 {
    right: 30.8527758257%; }
  .col-lg-4 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 38.2944483486%; }
    .col-lg-4:last-child {
      margin-right: 0; }
  .col-lg-push-4 {
    left: 41.1370344343%; }
  .col-lg-pull-4 {
    right: 41.1370344343%; }
  .col-lg-5 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 48.5787069571%; }
    .col-lg-5:last-child {
      margin-right: 0; }
  .col-lg-push-5 {
    left: 51.4212930429%; }
  .col-lg-pull-5 {
    right: 51.4212930429%; }
  .col-lg-6 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 58.8629655657%; }
    .col-lg-6:last-child {
      margin-right: 0; }
  .col-lg-push-6 {
    left: 61.7055516514%; }
  .col-lg-pull-6 {
    right: 61.7055516514%; }
  .col-lg-7 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 69.1472241743%; }
    .col-lg-7:last-child {
      margin-right: 0; }
  .col-lg-push-7 {
    left: 71.98981026%; }
  .col-lg-pull-7 {
    right: 71.98981026%; }
  .col-lg-8 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 79.4314827829%; }
    .col-lg-8:last-child {
      margin-right: 0; }
  .col-lg-push-8 {
    left: 82.2740688686%; }
  .col-lg-pull-8 {
    right: 82.2740688686%; }
  .col-lg-9 {
    float: left;
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 89.7157413914%; }
    .col-lg-9:last-child {
      margin-right: 0; }
  .col-lg-push-9 {
    left: 92.5583274772%; }
  .col-lg-pull-9 {
    right: 92.5583274772%; }
  .col-lg-10 {
    float: left;
    display: block;
    margin-right: 2.8425860857%;
    width: 100%; }
    .col-lg-10:last-child {
      margin-right: 0; }
  .col-lg-push-10 {
    left: 102.842586086%; }
  .col-lg-pull-10 {
    right: 102.842586086%; } }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  appearance: none;
  background-color: #9ADB00;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, button:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus {
    background-color: #7baf00;
    color: #fff; }
  button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    button:disabled:hover, input[type="button"]:disabled:hover, input[type="reset"]:disabled:hover, input[type="submit"]:disabled:hover {
      background-color: #9ADB00; }

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  font-weight: 600;
  margin-bottom: 0.375em;
  padding: 0; }

label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.375em; }

input,
select {
  display: block;
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
  font-size: 15px; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea,
select[multiple] {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
  font-size: 15px;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color 150ms ease;
  width: 100%; }
  input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, input:not([type]):hover, textarea:hover,
  select[multiple]:hover {
    border-color: #b1b1b1; }
  input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus,
  select[multiple]:focus {
    border-color: #9ADB00;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(136, 194, 0, 0.7);
    outline: none; }
  input[type="color"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="email"]:disabled, input[type="month"]:disabled, input[type="number"]:disabled, input[type="password"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="text"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="week"]:disabled, input:not([type]):disabled, textarea:disabled,
  select[multiple]:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }
    input[type="color"]:disabled:hover, input[type="date"]:disabled:hover, input[type="datetime"]:disabled:hover, input[type="datetime-local"]:disabled:hover, input[type="email"]:disabled:hover, input[type="month"]:disabled:hover, input[type="number"]:disabled:hover, input[type="password"]:disabled:hover, input[type="search"]:disabled:hover, input[type="tel"]:disabled:hover, input[type="text"]:disabled:hover, input[type="time"]:disabled:hover, input[type="url"]:disabled:hover, input[type="week"]:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover,
    select[multiple]:disabled:hover {
      border: 1px solid #ddd; }

textarea {
  resize: vertical; }

[type="search"] {
  appearance: none; }

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

[type="file"] {
  margin-bottom: 0.75em;
  width: 100%; }

select {
  margin-bottom: 1.5em;
  max-width: 100%;
  width: auto; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin-bottom: 0.75em; }
  dl dt {
    font-weight: 600;
    margin-top: 0.75em; }
  dl dd {
    margin: 0; }

table {
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #a6a6a6;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #ddd;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto; }

.aligncenter {
  display: block;
  margin: 11px auto;
  height: auto; }

.alignleft,
.alignright {
  margin-bottom: 11px;
  height: auto; }

@media screen and (min-width: 480px) {
  .alignleft {
    float: left;
    margin-right: 11px; }
  .alignright {
    float: right;
    margin-left: 11px; } }

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }
  .screen-reader-text:focus {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #333;
    display: block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */ }

.wrap > .content {
  z-index: 1;
  margin-top: 140px; }
  @media (min-width: 620px) {
    .wrap > .content {
      margin-top: 60px; } }
  .f-nav .wrap > .content {
    margin-top: 280px; }

.banner {
  transition: all .3s ease-in;
  z-index: 15;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff; }
  @media (min-width: 620px) {
    .banner {
      position: relative; } }
  .f-nav .banner {
    position: fixed; }
    @media (min-width: 620px) {
      .f-nav .banner > .container {
        padding: 30px 0 20px; } }
  .banner > .container {
    border-bottom: 1px solid #ddd;
    margin-bottom: 0px;
    padding: 30px 0 20px; }
    @media (min-width: 620px) {
      .banner > .container {
        padding: 120px 0; } }
    .banner > .container .brand {
      display: inline-block;
      background-image: url("../images/atxurra-zelaieta-arquitectos_logo-header.svg");
      background-repeat: no-repeat;
      background-size: cover;
      text-indent: -9999em;
      width: 220px;
      height: 22px;
      z-index: 450;
      position: relative;
      left: 20px; }
      @media (min-width: 620px) {
        .banner > .container .brand {
          width: 250px;
          height: 25px;
          left: 0px; } }
    .banner > .container .navigation-menu-button {
      display: block;
      float: right;
      position: relative;
      right: 30px;
      top: -5px;
      width: 30px;
      height: 30px;
      z-index: 400;
      font-size: 20px;
      color: #000;
      background-image: url("../images/menu.svg");
      background-repeat: no-repeat;
      transition: all .4s ease; }
      @media (min-width: 620px) {
        .banner > .container .navigation-menu-button {
          right: 0px; } }
      .banner > .container .navigation-menu-button:hover, .banner > .container .navigation-menu-button:focus {
        color: #585858;
        outline: none; }
      .showmenu .banner > .container .navigation-menu-button {
        background-image: url("../images/close.svg"); }

.nav-primary {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .showmenu .nav-primary {
    background-color: #fff;
    opacity: 1;
    visibility: visible;
    z-index: 100; }
  .nav-primary .logo-menu {
    margin: -30% 0 0; }
  .nav-primary .nav {
    margin-top: 100px; }
    @media (min-width: 620px) {
      .nav-primary .nav {
        margin-top: 200px; } }
    .nav-primary .nav > li {
      opacity: 0;
      border-bottom: 1px solid #ddd;
      transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .nav-primary .nav > li > a {
        text-decoration: none;
        display: block;
        padding: 10px 25px 10px;
        color: #000;
        font-size: 20px; }
        @media (min-width: 620px) {
          .nav-primary .nav > li > a {
            padding: 30px 0; } }
        .nav-primary .nav > li > a:focus, .nav-primary .nav > li > a:hover {
          background-color: transparent;
          color: #9ADB00; }
      .nav-primary .nav > li.active a {
        opacity: 0.6; }
      .showmenu .nav-primary .nav > li {
        opacity: 1; }
        .showmenu .nav-primary .nav > li:nth-child(1) {
          transition-delay: .1s; }
        .showmenu .nav-primary .nav > li:nth-child(2) {
          transition-delay: .2s; }
        .showmenu .nav-primary .nav > li:nth-child(3) {
          transition-delay: .3s; }
        .showmenu .nav-primary .nav > li:nth-child(4) {
          transition-delay: .4s; }
        .showmenu .nav-primary .nav > li:nth-child(5) {
          transition-delay: .5s; }
        .showmenu .nav-primary .nav > li:nth-child(6) {
          transition-delay: .6s; }
        .showmenu .nav-primary .nav > li:nth-child(7) {
          transition-delay: .7s; }
        .showmenu .nav-primary .nav > li:nth-child(8) {
          transition-delay: .8s; }
        .showmenu .nav-primary .nav > li:nth-child(9) {
          transition-delay: .9s; }
  .nav-primary .idiomas_inner {
    transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
    padding: 10px 25px 10px; }
    @media (min-width: 620px) {
      .nav-primary .idiomas_inner {
        padding: 30px 0; } }
    .showmenu .nav-primary .idiomas_inner {
      opacity: 1;
      transition-delay: .5s; }
    .nav-primary .idiomas_inner > a {
      text-decoration: none;
      padding: 5px 0;
      color: #000;
      font-size: 20px; }
      .nav-primary .idiomas_inner > a.active {
        opacity: .6; }
      .nav-primary .idiomas_inner > a:focus, .nav-primary .idiomas_inner > a:hover {
        background-color: transparent;
        color: #9ADB00; }
  .nav-primary ul.social-nav {
    padding: 10px 25px;
    display: flex; }
    @media (min-width: 955px) {
      .nav-primary ul.social-nav {
        padding: 0; } }
    .nav-primary ul.social-nav > li:not(last-child) {
      padding-right: 10px; }

.content-info > .container {
  border-top: 1px solid #ddd;
  margin-top: 50px;
  padding: 50px 0; }
  .content-info > .container section {
    margin-bottom: 50px; }
  .content-info > .container .nav {
    margin: 0;
    padding: 0; }
    .content-info > .container .nav li {
      display: inline-block; }
      .content-info > .container .nav li a::before {
        content: ' - '; }
      .content-info > .container .nav li:first-child a::before {
        content: ''; }

.estudio h2 {
  font-size: 1.6rem;
  margin-bottom: 50px; }

.estudio section {
  margin-bottom: 50px; }

.estudio .page-content {
  margin-bottom: 30px; }
  @media (min-width: 620px) {
    .estudio .page-content {
      column-count: 2;
      column-gap: 1rem; } }

.estudio .content-equipo .premio-item b, .estudio .content-equipo .premio-item strong {
  display: block;
  margin-top: 10px; }
  @media (min-width: 620px) {
    .estudio .content-equipo .premio-item b, .estudio .content-equipo .premio-item strong {
      display: inline; } }

.estudio .content-premios .premio-item {
  border-top: 1px solid #333;
  padding: 10px 0; }

.estudio .content-premios:last-child {
  border-bottom: 1px solid #333; }

.estudio .content-media .media-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .estudio .content-media .media-items .media-item {
    flex-grow: 0;
    margin-right: 20px;
    width: calc(100% * (1/2) - 20px - 1px); }
    @media (min-width: 620px) {
      .estudio .content-media .media-items .media-item {
        margin-right: 30px;
        width: calc(100% * (1/5) - 30px - 1px); } }
    .estudio .content-media .media-items .media-item:last-child {
      margin-right: 0; }
    .estudio .content-media .media-items .media-item h3 {
      margin: 10px 0 0 0; }
    .estudio .content-media .media-items .media-item .content {
      font-size: 1rem; }
    .estudio .content-media .media-items .media-item .image-content {
      overflow: hidden;
      position: relative; }
      .estudio .content-media .media-items .media-item .image-content img {
        max-width: 100%;
        height: auto;
        display: block;
        backface-visibility: hidden;
        transform: translateZ(0) scale(1, 1);
        transition: all .4s ease-in-out;
        transform: scale(1); }
        .estudio .content-media .media-items .media-item .image-content img:hover {
          transform: scale(1.1); }

.contacto .google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%); }
  .contacto .google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }

.proyecto-item {
  position: relative;
  margin-bottom: 30px;
  min-height: 170px;
  /*&:hover{
    .contenido{
      opacity: 1;
      visibility: visible;
    }
  }*/ }
  @media (min-width: 620px) {
    .proyecto-item {
      margin-bottom: 40px;
      min-height: 300px; } }
  .proyecto-item .image-content {
    overflow: hidden;
    position: relative; }
    .proyecto-item .image-content img {
      max-width: 100%;
      height: auto;
      display: block;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1, 1);
      transition: all .6s ease-in-out;
      transform: scale(1); }
      .proyecto-item .image-content img:hover {
        transform: scale(1.05); }
  .proyecto-item h2 {
    font-size: 1rem;
    margin: .75em 0; }

.single-proyecto .entry-title {
  margin: 0 0 5px; }
  .single-proyecto .entry-title ~ p:last-child {
    margin-bottom: 50px; }
  .single-proyecto .entry-title ~ p {
    margin: 0; }

@media (min-width: 620px) {
  .single-proyecto .entry-content {
    column-count: 2;
    column-gap: 1rem; } }

.single-proyecto .entry-pics ul.pics {
  margin-top: 50px; }
  .single-proyecto .entry-pics ul.pics li {
    margin-bottom: 5px; }

.single-proyecto .arrowNav a {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 26px;
  width: 80px; }
  .single-proyecto .arrowNav a img {
    visibility: visible;
    opacity: 1;
    width: 80px;
    height: 80px;
    transition: all .2s ease-in; }
    .single-proyecto .arrowNav a img.icono {
      opacity: 1;
      width: 40px;
      height: 30px;
      min-height: 80px;
      background-color: #fff;
      z-index: 10; }

.single-proyecto .arrowNav .arrowLeft a {
  position: relative;
  float: left;
  z-index: 10;
  top: 40%;
  left: 0;
  padding: 15px 0px;
  transition: .2s ease-in; }
  @media (min-width: 620px) {
    .single-proyecto .arrowNav .arrowLeft a {
      position: fixed;
      left: -10px;
      padding: 15px 10px; } }
  .single-proyecto .arrowNav .arrowLeft a img.icono {
    border-right: 1px solid #ddd; }
  .single-proyecto .arrowNav .arrowLeft a img.size-thumbnail {
    position: absolute;
    left: 40px;
    top: 15px; }
    @media (min-width: 620px) {
      .single-proyecto .arrowNav .arrowLeft a img.size-thumbnail {
        left: -80px; } }
  .single-proyecto .arrowNav .arrowLeft a.show_pict img {
    left: 50px;
    opacity: 1;
    visibility: visible; }

.single-proyecto .arrowNav .arrowRight a {
  position: relative;
  float: right;
  z-index: 10;
  top: 40%;
  right: 0px;
  padding: 15px 0px;
  transition: .2s ease-in; }
  @media (min-width: 620px) {
    .single-proyecto .arrowNav .arrowRight a {
      position: fixed;
      right: -40px; } }
  .single-proyecto .arrowNav .arrowRight a img.icono {
    border-left: 1px solid #ddd;
    right: -40px;
    position: relative; }
    @media (min-width: 620px) {
      .single-proyecto .arrowNav .arrowRight a img.icono {
        right: 0px; } }
  .single-proyecto .arrowNav .arrowRight a img.size-thumbnail {
    position: absolute;
    right: 40px;
    top: 15px;
    border-left: 1px solid #ddd; }
    @media (min-width: 620px) {
      .single-proyecto .arrowNav .arrowRight a img.size-thumbnail {
        right: -80px; } }
  .single-proyecto .arrowNav .arrowRight a.show_pict img.icono {
    right: -40px; }
    @media (min-width: 620px) {
      .single-proyecto .arrowNav .arrowRight a.show_pict img.icono {
        right: 0; } }
  .single-proyecto .arrowNav .arrowRight a.show_pict img.size-thumbnail {
    right: 50px;
    opacity: 1;
    visibility: visible; }
    @media (min-width: 620px) {
      .single-proyecto .arrowNav .arrowRight a.show_pict img.size-thumbnail {
        right: 80px; } }

body#tinymce {
  margin: 12px !important; }

body {
  color: #333;
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 300; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 0.75em; }

h1, .h1 {
  font-size: 25.6px;
  font-size: 1.6rem; }

h2, .h2 {
  font-size: 17.6px;
  font-size: 1.1rem; }

h3, .h3 {
  font-size: 16px;
  font-size: 1rem; }

h4, .h4 {
  font-size: 13.6px;
  font-size: 0.85rem; }

h5, .h5 {
  font-size: 16px;
  font-size: 1rem; }

h6, .h6 {
  font-size: 13.6px;
  font-size: 0.85rem; }

p {
  margin: 0 0 0.75em; }

a {
  color: #333;
  text-decoration: none;
  transition: color 150ms ease; }
  a:active, a:focus, a:hover {
    color: #9ADB00;
    color: #8AC500; }

hr {
  border-bottom: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto; }
