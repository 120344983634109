@mixin font-size($sizeValue: 1) {
  font-size: ($sizeValue * 16) * 1px;
  font-size: $sizeValue * 1rem;
}



// Grid Mixins

// Create the grid for a given size
// This will loop over the number of columns and create a css class
// for each. If it's the last gride size, then let's not add a float
@mixin grid-core($size){
  @for $i from 1 through $grid-columns{
    .col-#{$size}-#{$i} {
      // Ignore float for the largest grid size
      @if $i < $grid-columns{
        float: left;
      }
      @include span-columns($i);

      // Grid last item in row
      @if $grid-columns%$i == 0 {
        //@include omega(4n);
      }
    }

    // If it's not the screen-xs, then let's add push and pull helper classes
    @if $size != "xs" {
      .col-#{$size}-push-#{$i} {
        left: flex-grid($i, $grid-columns) + flex-gutter($grid-columns);
      }
      .col-#{$size}-pull-#{$i}{
        right: flex-grid($i, $grid-columns) + flex-gutter($grid-columns);
      }
    }
  }
}


/*********************
BREAKPOINTS
*********************/

@mixin breakpoint($point) {
  @media (min-width: $point) { @content ; }
}

