body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: $body-font-weight;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: $base-font-size;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

h1, .h1 {
  @include font-size(1.6);
}

h2, .h2 {
  @include font-size(1.1);
}

h3, .h3 {
  @include font-size(1);
}

h4, .h4 {
  @include font-size(.85);
}

h5, .h5 {
  @include font-size(1);
}

h6, .h6 {
  @include font-size(.85)
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $dark-gray;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color:  $action-color;
    color:  #8AC500;
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto;
}
