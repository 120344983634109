
body{
  margin: 0 25px;
  @include breakpoint($sm) {
    margin: 0;
  }
}

a{
  outline: none;
}

b, strong {
  font-weight: inherit;
  font-weight: 600;
}

.push-left{
  float: left;
  clear: both;
}
.push-right{
  float: right;
  clear: both;
}

.wrapper{
  position: relative;
  opacity: 1;
  .loading &{
    opacity: 0;
  }
}

/* Plugin Happy Social */
.hpscl {
  display: flex;
  margin-top: 50px;
}


.block-revealer__element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  pointer-events: none;
  opacity: 0;
  z-index: 10;
}

.preload{
  position: fixed;
  top: 100%;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  text-align: center;
  //opacity: 0;
  //visibility: hidden;
  transition:  all 0.3s cubic-bezier(.645,.045,.355,1);
  background-color: #dddddd;
  .loading &{
    top: 0;
    //opacity: 1;
    //visibility: visible;
    z-index: 1000;
  }

  .loader {
    position: absolute;
    left: 0;
    bottom: 60%;
    width: 100%;
    opacity: 0;
    cursor: default;
    pointer-events: none;


    // Preload fake
    .pulse {
      width: 40px;
      height: 40px;
      margin: 100px auto 10px;
      background-color: $blanco;
      background-color: $negro;
      border-radius: 100%;
      -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
      animation: sk-scaleout 1.0s infinite ease-in-out;
    }
    @keyframes sk-scaleout {
      0% {
        transform: scale(0);
      } 100% {
        transform: scale(1.0);
        opacity: 0;
      }
    }

    .percent{
      font-size: 20px;
      color: $negro;
      // font-family: $tga_b;
      // color: $blanco;
      //opacity: 0.3;
    }

    .loading &{
      opacity: 1;
      animation: animInitialHeader 1s cubic-bezier(0.7,0,0.3,1) both;
      animation-delay: 0.2s;
    }
  }
}

@keyframes animInitialHeader {
  from {
    opacity: 0;
    transform: translate3d(0,800px,0);
  }
}

