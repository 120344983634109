
.estudio{
  h2{
    font-size: 1.6rem;
    margin-bottom: 50px;
  }
  section{
    margin-bottom: 50px;
  }
  .page-content{
    margin-bottom: 30px;
    @include breakpoint($sm) {
      column-count: 2;
      column-gap: 1rem;
    }
  }

  .content-estudio{}
  .content-equipo{
    .premio-item{
      b, strong{
        display: block;
        margin-top: 10px;
        @include breakpoint($sm) {
          display: inline;
        }
      }
    }
  }
  .content-premios{
    .premio-item{
      border-top: 1px solid $dark-gray;
      padding: 10px 0;
    }
    &:last-child{
      border-bottom: 1px solid $dark-gray;
    }
  }
  .content-media{
    .media-items{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .media-item{
        flex-grow: 0;
        margin-right: 20px;
        width: calc(100% * (1/2) - 20px - 1px);
        @include breakpoint($sm) {
          margin-right: 30px;
          width: calc(100% * (1/5) - 30px - 1px);
        }
        &:last-child{
          margin-right: 0;
        }
        h3{
          margin: 10px 0 0 0;
        }
        .content{
          font-size: 1rem;
        }
        .image-content{
          overflow: hidden;
          position: relative;
          img{
            max-width: 100%;
            height: auto;
            display: block;
            backface-visibility: hidden;
            transform: translateZ(0) scale(1.0, 1.0);
            transition: all .4s ease-in-out;
            transform: scale(1);
            &:hover{
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}

.contacto{
  .google-maps {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}