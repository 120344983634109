.wrap > .content {
  // transition: all .3s ease-in;
  z-index: 1;
  margin-top: 140px;
  @include breakpoint($sm) {
    margin-top: 60px;
  }
  .f-nav &{
    margin-top: 280px;
  }
}

.banner{
  transition: all .3s ease-in;
  z-index: 15;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  @include breakpoint($sm) {
    position: relative;
  }
  .f-nav &{
    position: fixed;
    &>.container{
      // margin-bottom: 0px;
      @include breakpoint($sm) {
        padding: 30px 0 20px;
      }
    }
  }

  &>.container{
    border-bottom: 1px solid $light-gray;
    margin-bottom: 0px;
    padding: 30px 0 20px;
    @include breakpoint($sm) {
      padding: 120px 0;
    }
    // padding: 70px 0 10px;
    .brand{
      display: inline-block;
      background-image: url("../images/atxurra-zelaieta-arquitectos_logo-header.svg");
      background-repeat: no-repeat;
      background-size: cover;
      text-indent: -9999em;
      width: 220px;
      height: 22px;
      z-index: 450;
      position: relative;
      left: 20px;
      @include breakpoint($sm) {
        width: 250px;
        height: 25px;
        left: 0px;
      }
    }
    .navigation-menu-button{
      display: block;
      float: right;
      position: relative;
      right: 30px;
      top: -5px;
      width: 30px;
      height: 30px;
      z-index: 400;
      font-size: 20px;
      color: $negro;
      background-image: url('../images/menu.svg');
      background-repeat: no-repeat;
      transition: all .4s ease;
      @include breakpoint($sm) {
        right: 0px;
      }
      &:hover, &:focus{
        color: $gris-claro;
        outline: none;
      }
      .showmenu &{
        background-image: url('../images/close.svg');
      }
    }
  }
}





.nav-primary{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all .4s cubic-bezier(.645,.045,.355,1) ;

  .showmenu &{
    background-color: $blanco;
    opacity: 1;
    visibility: visible;
    z-index: 100;
  }
  .logo-menu{
    margin: -30% 0 0;
  }
  .nav{
    margin-top: 100px;
    @include breakpoint($sm) {
      margin-top: 200px;
    }
    &>li {
      opacity: 0;

      border-bottom: 1px solid #ddd;
      transition: all .6s cubic-bezier(.645,.045,.355,1) ;
      &>a {
        // @include menu;
        text-decoration: none;
        display: block;
        padding: 10px 25px 10px;
        //margin: 5px 0;
        color: $negro;
        font-size: 20px;
        @include breakpoint($sm) {
          padding: 30px 0;
        }
        &:focus,
        &:hover{
          background-color: transparent;
          color: $action;
        }
      }
      &.active{
        a{
          opacity: 0.6;
        }
      }
      .showmenu &{
        opacity: 1;
        &:nth-child(1){ transition-delay: .1s }
        &:nth-child(2){ transition-delay: .2s }
        &:nth-child(3){ transition-delay: .3s }
        &:nth-child(4){ transition-delay: .4s }
        &:nth-child(5){ transition-delay: .5s }
        &:nth-child(6){ transition-delay: .6s }
        &:nth-child(7){ transition-delay: .7s }
        &:nth-child(8){ transition-delay: .8s }
        &:nth-child(9){ transition-delay: .9s }
      }
    }
  }
  .idiomas_inner{
    transition: all .6s cubic-bezier(.645,.045,.355,1) ;
    opacity: 0;
    padding: 10px 25px 10px;
    @include breakpoint($sm) {
      padding: 30px 0;
    }
    .showmenu &{
      opacity: 1;
      transition-delay: .5s;
    }
    &>a {
      // @include menu;
      text-decoration: none;
      padding: 5px 0;
      color: $negro;
      font-size: 20px;
      &.active{
        opacity: .6;
        //color: $light-gray;
      }
      &:focus,
      &:hover{
        background-color: transparent;
        color: $action;
      }
    }
  }
  ul.social-nav {
    padding: 10px 25px;
    @include breakpoint($md) {
      padding: 0;
    }
    display: flex;
    &>li:not(last-child){
      padding-right: 10px;
    }
  }
}
