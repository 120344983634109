.content-info{
  &>.container{
    border-top: 1px solid $light-gray;
    margin-top: 50px;
    padding: 50px 0;
    section{
      margin-bottom: 50px;
    }
    .nav{
      margin: 0;
      padding: 0;
      li{
        display: inline-block;
        a{
          &::before{
            content: ' - ';
          }
        }
        &:first-child{
          a::before{
            content: '';
          }
        }
      }
    }
  }
}