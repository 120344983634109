.proyecto-item{
  position: relative;
  margin-bottom: 30px;
  min-height: 170px;
  @include breakpoint($sm){
    margin-bottom: 40px;
    min-height: 300px;
  }
  //border: 5px solid #dedede;
  /*&:hover{
    .contenido{
      opacity: 1;
      visibility: visible;
    }
  }*/
  .image-content{
    overflow: hidden;
    position: relative;
    img{
      max-width: 100%;
      height: auto;
      display: block;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1.0, 1.0);
      transition: all .6s ease-in-out;
      transform: scale(1);
      &:hover{
        transform: scale(1.05);
      }
    }
  }
  h2{
    font-size: 1rem;
    margin: .75em 0;
  }
}


.single-proyecto{
  .entry-title{
    margin: 0 0 5px;
    &~p:last-child{
      margin-bottom: 50px;
    }
    &~p{
     margin: 0;
    }
  }
  .entry-content{
    @include breakpoint($sm) {
      column-count: 2;
      column-gap: 1rem;
    }
  }

  .entry-pics{
    ul.pics{
      margin-top: 50px;
      li{
        margin-bottom: 5px;
      }
    }
  }
  .arrowNav{
    a{
      display: flex;
      align-items: center;
      //background: #225773;
      //color: #fff;
      text-decoration: none;
      font-size: 26px;
      width: 80px;
      img{
        visibility: visible;
        opacity: 1;
        width: 80px;
        height: 80px;
        transition: all .2s ease-in;
        &.icono{
          opacity: 1;
          width: 40px;
          height: 30px;
          min-height: 80px;
          background-color: #fff;
          z-index: 10;
        }
      }
    }
    .arrowLeft a {
      position: relative;
      float: left;
      z-index: 10;
      top: 40%;
      left: 0;
      padding: 15px 0px;
      transition: .2s ease-in;
      @include breakpoint($sm){
        position: fixed;
        left: -10px;
        padding: 15px 10px;
      }
      img.icono{
        border-right: 1px solid $light-gray;
      }
      img.size-thumbnail{
        position: absolute;
        left: 40px;
        top: 15px;
        @include breakpoint($sm){
          left: -80px;
        }
      }
      &.show_pict{
        img{
          left: 50px;
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .arrowRight a {
      position: relative;
      float: right;
      z-index: 10;
      top: 40%;
      right: 0px;
      padding: 15px 0px;
      transition: .2s ease-in;
      @include breakpoint($sm){
        position: fixed;
        right: -40px;
      }
      img.icono{
        border-left: 1px solid $light-gray;
        right: -40px;
        position: relative;
        @include breakpoint($sm){
          right: 0px;
        }
      }
      img.size-thumbnail{
        position: absolute;
        right: 40px;
        top: 15px;
        border-left: 1px solid $light-gray;
        @include breakpoint($sm){
          right: -80px;
        }
      }
      &.show_pict{
        img.icono{
          right: -40px;
          @include breakpoint($sm){
            right: 0;
          }
        }
        img.size-thumbnail{
          right: 50px;
          @include breakpoint($sm){
            right: 80px;
          }
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

}
