// Typography
$base-font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
$heading-font-family: $base-font-family;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi: 600;
$font-weight-bold: 700;

$body-font-weight: $font-weight-light;
$bold-font-weight: $font-weight-semi;
$heading-font-weight: $font-weight-normal;

// Font Sizes
$base-font-size: 15px;

// Line height
$base-line-height: 1.5;
$computed-line-height:    floor(( $base-font-size * $base-line-height));
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;
$blanco: #fff;
$negro: #000;
$action: #9ADB00;
$gris-claro: #585858;
$gris-medio: #333333;
$gris-oscuro: #222222;
$gris-pie: $gris-medio;
$gris-subpie: $gris-oscuro;

$brand-primary:         $dark-gray;
$brand-primary:         $action;
$base-background-color: $blanco;
$base-border-color: #ccc;

// Font Colors
$base-font-color: #1A1A1A;


// Font Colors
$base-font-color: $dark-gray;
$action-color: $action;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;



// Displays the visual grid when set to true.
$visual-grid: false;
//Sets the visual grid color
$visual-grid-color: #eee;
// Sets the z-index property of the visual grid. Can be back (behind content) or front (in front of content).
$visual-grid-index: back;
// Sets the opacity property of the visual grid.
$visual-grid-opacity: 0.9;

