// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($computed-line-height / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($computed-line-height / 2);
  height: auto;
}
@include media($small-screen-up) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($computed-line-height / 2);
  }
  .alignright {
    float: right;
    margin-left: ($computed-line-height / 2);
  }
}

// Text meant only for screen readers
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;

	&:focus {
		background-color: $base-background-color;
		border-radius: 3px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
		clip: auto !important;
		color: $base-font-color;
		display: block;
		@include font-size(0.875);
		font-weight: bold;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar. */
	}
}
