@import '../../bower_components/neat/app/assets/stylesheets/neat-helpers';

// layout
// Grid system
$grid-columns: 10;
$sidebar-columns: 4;
$max-width: 1170px;

// Breakpoints
$small-screen: 480px;
$medium-screen: 620px;
$large-screen: 955px;
$wide-screen: 1200px;

$xs: $small-screen;
$sm: $medium-screen;
$md: $large-screen;
$lg: $wide-screen;
// $xl:

$small-screen-up: new-breakpoint(min-width $small-screen);
$medium-screen-up: new-breakpoint(min-width $medium-screen);
$large-screen-up: new-breakpoint(min-width $large-screen);
$wide-screen-up: new-breakpoint(min-width $wide-screen);




// Grid system
// --------------------------------------------------

// Number of columns in the grid system
//$grids-columns:              12;
// Padding, to be divided by two and applied to the left and right of all columns
$gutter:                     modular-scale(1);

//$max-width:                  1200px;
$max-width:                  970px;



// Responsive Breakpoints
// ------------------------------------------------------

$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
//$screen-lg:                  1200px !default;
$screen-lg:                  970px !default;
$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;
$max-width:                  $screen-lg;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
